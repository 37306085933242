export const courses = [
  {
    title: "Excel-perusteet",
    description:
      "Opettele tärkeimmät työelämässä tarvittavat Excel-taidot. Vaikka olisit jo käyttänyt Exceliä pitkään opit tällä kurssilla todennäköisesti tehokkaampia tapoja tuttujen asioiden tekemiseen. ",
    cta: "Lue lisää ja ole yhteydessä.",
    url: "/fi/excel-kurssi-netissa",
    id: "macros",
  },
  {
    title: "Excel-jatko",
    description:
      "Syvennä funktioihin liittyvää osaamista, tutustu tuotehakujen tekemiseen sekä opi luomaan ja käyttämään Pivot-taulukoita.",
    cta: "Tutustu kurssin sisältöön",
    url: "/fi/excel-jatkokurssi",
    id: "automatisation",
  },
];
