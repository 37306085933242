import React from 'react';
import PropTypes from 'prop-types';
import { siteMetadata } from '../../gatsby-config';
import { Link } from 'gatsby';
import { getKeyFromString } from '../utils/utils.js';
import excelShape from "../images/Excel_shape.svg";

export default function CourseList({ items, extra = false }) {
  const serviceItems = items.map((item, index) => (
    <div className="service-item" key={getKeyFromString(item.title)}>
      <div className="row align-items-center">
        <div
          className={
            'col-md-9' + ' ' + ((index + 1) % 2 === 0 ? 'order-12' : '')
          }
        >
          <LinkWrapper item={item} extra={extra.title}>
            <div className="text-content">
              <h3 className="service-item-title">{item.title}</h3>
              <div
                className="description lead"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></div>
              {!extra ? (
                <div className="btn cta-button-x2 mt-4">{item.cta}</div>
              ) : (
                undefined
              )}
            </div>
          </LinkWrapper>
        </div>
        <div className="col-md d-none d-md-block">
          <div className="item-image-wrapper">
            {<img src={excelShape} />}
          </div>
        </div>
      </div>
    </div>
  ));
  return serviceItems;
}

function LinkWrapper(props) {
  const { item, extra } = props;
  if (extra) {
    return (
      <>
        {props.children}
        <div className="">
          <Link to={item.url} className="btn cta-button-x2 mt-4">
            {extra}
          </Link>
        </div>
      </>
    );
  }
}

// See https://github.com/facebook/prop-types
LinkWrapper.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    subject_tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  // allCourses: PropTypes.shape({
  //   edges: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       node: PropTypes.shape({
  //         id: PropTypes.string.isRequired,
  //         url_path: PropTypes.string.isRequired,
  //       }).isRequired,
  //     })
  //   ).isRequired,
  // }).isRequired,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

function getItemUrlBasedOnId(id, allCourses) {
  let url = siteMetadata.paths.course_listing;
  const courseObject = allCourses.edges.find(item => item.node.id === id);
  if (courseObject) {
    url = url + '/' + courseObject.node.url_path;
  }
  return url;
}
