import React from "react";
import { SEO } from "../components/seo";
import Layout from "../components/layout";
import CourseList from "../components/courseList";
import { courses } from "../structuredData/structuredData";
import bg from "../images/bg_image_trainings_page.jpg";
import logo from "../images/Logo@2x.png";

export default props => {
  return (
    <>
      <SEO
        title="Excel-verkkokurssit työelämän tarpeisiin"
        description="Excel-verkkokurssit työelämän tarpeisiin"
      />
      <Layout
        mainTitle={"Excel-verkkokurssit"}
        mainTitleClasses={"mt-3"}
        additionalTitle={"Työelämän tarpeisiin"}
        headerBgStyles={{ background: "url(" + bg + ")" }}
        headerTitleStyle={{ color: "#0086ef" }}
        headerAdditionalTitleStyle={{ color: "#019999" }}
        additionalTitleClassNames={"mb-3 mt-md-0 mb-md-3"}
        headerNavLinkStyle={{ color: "#7a7c81" }}
        logo={logo}
        {...props}
      >
        <div className="services">
          <CourseList
            items={courses}
            allCourses={null}
            extra={{ title: "Siirry verkkokurssiin" }}
          />
        </div>
      </Layout>
    </>
  );
};